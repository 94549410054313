import React, { Component } from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { device } from '../styles/breakpoints';
import { Link } from 'gatsby';

const Slide = styled.div`
  width: 80%;
  height: 100%;
  h4 {
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.black};
  }
@media ${device.laptop} {
  width: 90%;
  height: 30vh;
  max-height: 40vh;
  min-height: 40vh;
  padding: 0 1em;
  box-sizing: border-box;
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  text-align: left;
}
`
const Arrow = styled.button`
  width: 5%;
`

const Section = styled.div`
  @media ${device.laptop} {
    margin: 3rem auto 5rem auto;
    width: 80%;
  }
  width: 90%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-content: center;
  margin: 6rem auto;
`
const CarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  @media ${device.laptop} {
    margin-bottom: 1rem;
    height: 30vh;
    max-height: 30vh;
    min-height: 30vh;
    margin-bottom: 6rem;
  }

`
const CTAWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin: 2rem 0;
`
const Row = styled.div`
  width: 100%;
`
const PrimaryCTA = styled.a`
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  font-family: 'Aladin', serif;
  letter-spacing: 0.8px;
  line-height: 2rem;
  padding: 1.5rem 2.5rem;
  border: 1px solid ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.black};
  border-radius: 2px;
  text-align: center;
  @media ${device.laptop} {
    max-width: 50vw;
    margin: 0 auto;
  }
`
const SecondaryCTA = styled.a`
  font-size: 1.8rem;
  text-decoration: none;
  font-weight: 400;
  color: ${props => props.theme.colors.black};
  font-family: 'Aladin', serif;
  letter-spacing: 0.8px;
  line-height: 2rem;
  padding: 1.5rem 2.5rem;
  text-align: center;
  @media ${device.laptop} {
    max-width: 50vw;
    margin: 0 auto;
  }
  &::clicked{
    color: ${props => props.theme.colors.black};
  }

`
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
`
class Carousel extends Component {
  state = {
    currentIndex: 0,
  }

  textInterval = 0;

  componentDidMount(){
    this.autoAdvanceSlide();
  }

  nextSlide(){
    if(this.state.currentIndex + 1 === this.props.slides.length){
      this.setState({ currentIndex: 0 });
    } else{
      this.setState({ currentIndex: this.state.currentIndex+ 1 });
    }
  }

  previousSlide(){
    if(this.state.currentIndex === 0){
      this.setState({ currentIndex: this.props.slides.length - 1 });
    } else {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
    }
  }

  autoAdvanceSlide(){
    this.textInterval = setInterval(() => {
      this.nextSlide();
    }, 13000);
  }

  componentWillUnmount(){
    clearInterval(this.textInterval);
  }

  render(){
    const { slides } = this.props
    const slideIndex = this.state.currentIndex;
    return(
      <Section>
        <CarouselWrapper>
          <Arrow onClick={() => this.previousSlide()}
            aria-label="Previous Slide"
          >
            <StyledIcon icon={faAngleLeft} />
          </Arrow>
          <Slide>
            <h4>{slides[slideIndex].node.testimony.childMarkdownRemark.rawMarkdownBody}</h4>
            <h4>- {slides[slideIndex].node.author}</h4>
          </Slide>
          <Arrow onClick={() => this.nextSlide()}
            aria-label="Next Slide"
          >
            <StyledIcon icon={faAngleRight} />
          </Arrow>
        </CarouselWrapper>
        <CTAWrapper>
          <PrimaryCTA
            href="#contact"
          >
            Schedule an Intro Call
          </PrimaryCTA>
          <SecondaryCTA
            href="#contact"
            onClick={()=> this.props.hideCalendar()}
          >
              Not ready yet?<br/>Send Me a Note
          </SecondaryCTA>
        </CTAWrapper>
      </Section>
    )
  }
}

export default Carousel;
