import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'


const List = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Tag = styled.li`
  display: inline-block;
  margin: 0 0.25em 0.25em 0;
  div {
    float: left;
    transition: 0.2s;
    padding: 0.5em;
    text-transform: capitalize;
    text-decoration: none;
    color: ${props => props.theme.colors.black};
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 1rem;
  margin-right: 0.5rem;
`

const TagList = props => {
  return (
    <List>
      {props.tags.map((tag, index) => (
        <Tag key={index}>
          <div>
          <StyledIcon icon={faTag} />
          {tag.title}
          </div>
        </Tag>
      ))}
    </List>
  )
}

export default TagList
