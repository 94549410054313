import React from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faSpotify, faSoundcloud, faYoutube, faBandcamp } from '@fortawesome/free-brands-svg-icons';


const List = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Link = styled.a`
  display: inline-block;
  margin: 0 0.25em 0.25em 0;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 2px;
  padding: 0.5em;
  float: left;
  transition: 0.2s;
  text-transform: capitalize;
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  &:hover {
    background: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    svg {
      color: ${props => props.theme.colors.white};
    }
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 1rem;
  position: relative;
  margin: 0.125rem;
`

const AudioLink = props => {
  return(
      <List>
        {props.links.map((link, index) => (
          <Link key={index} href={link.url} target="_blank" rel="noopener noreferrer">
          {
            link.type == 'Spotify' ?
            <StyledIcon icon={faSpotify} />
            :
            link.type == 'Bandcamp' ?
            <StyledIcon icon={faBandcamp} />
            :
            link.type == 'Soundcloud' ?
            <StyledIcon icon={faSoundcloud} />
            :
            link.type == 'Youtube' ?
            <StyledIcon icon={faYoutube} />
            :
            null
          }
          </Link>
        ))}
      </List>
  )
}
export default AudioLink
