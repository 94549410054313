import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { device } from '../styles/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
width: 80%;
margin: 0 auto;
display: flex;
flex-flow: row wrap;
&::before {
  content: '';
  background: black;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  transition: 0.3s all;
  opacity: ${props => (props.overlay ? '.8' : '0')};
  visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
}
`
const Photo = styled.div`
    @media ${device.laptop} {
    margin: 0 2% 2% 0;
    width: 30%;
    height: 20vw;
    display: inline-block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: ${props => props.theme.colors.secondary};
      &::before {
        mix-blend-mode: lighten;
        background-color: ${props => props.theme.colors.black};
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
    margin: 0 2% 2% 0;
    width: 100%;
    height: 70vw;
    display: inline-block;
    overflow: hidden;
    position: relative;
    background-color: ${props => props.theme.colors.secondary};
      &::before {
        mix-blend-mode: lighten;
        background-color: ${props => props.theme.colors.black};
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
`

const StyledImg = styled(Img)`
    margin: 0 2% 2% 0;
    width: 100%;
    height: 20vw;
    border-radius: 2px;
    opacity: 1;
    flex:                1 0 100%;
    height:              100%;
    max-width:           100%;
    object-fit:          cover;
    position:            relative;
    width:               100%;
`
const ModalImg = styled(Img)`
  @media ${device.laptop} {
    height: auto;
    width: 50%;
  }
  height: auto;
  width: 80vw;
`
const Modal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow:hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  background: white;
  padding: 2em;
  border-radius: 2px;
  box-sizing: border-box;
  @media ${device.laptop} {
    width: 60vw;
    height: auto;

  }
  width: 100vw;
  height: auto;
  max-height: 80%;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  h5{
    color: ${props => props.theme.colors.black};
    margin-top: 1rem;
  }
  a {
    text-decoration: solid underline 1px ${props => props.theme.colors.black};
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 2%;
  left: 2%;
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 1.5rem;
  position: absolute;
  top: 2%;
  left: 2%;
  cursor: pointer;
`

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      photoIndex: 0
    }
  }

  toggleModal = (newIndex) => {
    if (newIndex) {
      this.setState({ photoIndex: newIndex, showModal: !this.state.showModal});
    } else {
      this.setState({ photoIndex: 0, showModal: !this.state.showModal });
    }
  }

  render(){
    const { content } = this.props;
    return(
      <Container overlay={this.state.showModal}>
      {
        content.map((photo, index) => {
          return(
              <Photo
                key={index}
                onClick={() => this.toggleModal(index)}
              >
                <StyledImg fluid={photo.node.image.fluid}/>
              </Photo>
          )
        })
      }
      <Modal visible={this.state.showModal} >
        <StyledIcon icon={faTimes} onClick={() => this.toggleModal()}/>
        <ModalImg fluid={content[this.state.photoIndex].node.image.fluid}/>
        <h5>{content[this.state.photoIndex].node.title}</h5>
        <a href={content[this.state.photoIndex].node.albumLink} target="_blank" rel="noopener noreferrer">
          <h5>Listen Now</h5>
        </a>
      </Modal>
      </Container>
    )
  }
}
export default Gallery;
