import React, { Component } from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import PortfolioItem from "../components/PortfolioItem"
import PricingItem from "../components/PricingItem"
import Hero from "../components/Hero"
import Bio from "../components/Bio"
import Container from "../components/Container"
import ContactSection from "../components/ContactSection"
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import StickyPlayer from '../components/StickyPlayer'
import Gallery from '../components/Gallery'
import Carousel from '../components/Carousel'
import Services from '../components/Services'

const Title = styled.div`
  margin: 2rem auto;
  width: 80%;
  color: ${props => props.theme.colors.black};
`

class App extends Component {

  state = {
    trackIndex: 0,
    isExpanded: false,
    showCal: true
  }

  setPlayingFromServices(trackID){
    let newIndex;
    for(var i = 0; i < this.props.tracks.length; i++){
      if(trackID == this.props.tracks[i].node.audio.file.id){
        newIndex = i;
      }
    }
    this.setState({trackIndex: newIndex, isExpanded: true});
  }

  setPlayingTrackV2(trackID){
    let newIndex;
    for(var i = 0; i < this.props.tracks.length; i++){
      if(trackID == this.props.tracks[i].node.audio.file.id){
        newIndex = i;
      }
    }
    this.setState({trackIndex: newIndex});
  }

  expandAudioPlayer(){
    this.setState({ isExpanded: true });
  }

  collapseAudioPlayer(){
    this.setState({ isExpanded: false });
  }

  hideCalendar(){
    this.setState({ showCal: false });
  }

  showCalendar(){
    this.setState({ showCal: true});
  }

  render(){
    return(
      <Layout
        socialLinks={this.props.socialLinks}
      >
        <Container>
        <Hero
          title={this.props.title}
          subtitle={this.props.subTitle}
          videoSrc={this.props.videoSrc}
          fallbackImg={this.props.fallbackImg}
        />
        <Bio body={this.props.body} />
        <Services
          content={this.props.services}
          setPlayingtrack={trackID => this.setPlayingFromServices(trackID)}
        />
        <Carousel
          slides={this.props.slides}
          showCalendar={() => this.showCalendar()}
          hideCalendar={() => this.hideCalendar()}
        />
        <Title><h1>Production Work</h1></Title>
        <Gallery content={this.props.gallery} />
        <Title id="contact"><h1>Let's Work Together</h1></Title>
        <ContactSection
          showCalendar={() => this.showCalendar()}
          hideCalendar={() => this.hideCalendar()}
          showCal={this.state.showCal}
        />
        </Container>
        <StickyPlayer
          tracks={this.props.tracks}
          setPlayingtrack={trackID => this.setPlayingTrackV2(trackID)}
          expandAudioPlayer={() => this.expandAudioPlayer()}
          collapseAudioPlayer={() => this.collapseAudioPlayer()}
          trackIndex={this.state.trackIndex}
          isExpanded={this.state.isExpanded}
        />
      </Layout>
    )
  }
}

export default App;
