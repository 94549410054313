import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  width: 100%;
  flex-grow: 1;
  background-color: ${props => props.theme.colors.secondary};

`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
