import React from 'react';
import styled from 'styled-components'

const StyledBio = styled.div`
  width: 80%;
  margin: 10vh auto 0 auto;
  background-color: ${props => props.theme.colors.secondary};
  h5 {
    color: ${props => props.theme.colors.black};
  }
`
const Title = styled.div`
  margin: 2rem auto;
  color: ${props => props.theme.colors.black};

`

const Bio = props => {
  const { body } = props;
  return(
    <StyledBio>
      <h5>{body}</h5>
    </StyledBio>
  )

}

export default Bio;
