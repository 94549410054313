import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSpotify, faSoundcloud, faYoutube, faFacebook, faTwitter, faBandcamp, faTwitch } from '@fortawesome/free-brands-svg-icons';
import logo from '../../static/logos/logo_blue.svg'
import { device } from '../styles/breakpoints';

const StyledFooter = styled.footer`
  .disclaimer{
    color: ${props => props.theme.colors.white50};
    padding: 2rem;
    width: 100%;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 5rem 1rem 4rem 1rem;
  background-image: linear-gradient(${props => props.theme.colors.secondary} 20%,  ${props => props.theme.colors.white});
  @media ${device.laptop} {
    flex-flow: row nowrap;
    padding: 5rem 1rem;
    .container{
      width: 33%;
    }
  }
  ul{
    padding: 1rem;
  }

  h5, a, p{
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }

  .logo{
    height: 12vh;
    width: auto;
    margin: 0 auto;
  }
`

const SubFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem 10rem 1rem;
  background-color: #ffffff;
  @media ${device.laptop} {
    flex-flow: row nowrap;
    padding: 5rem 1rem;
    .container{
      width: 33%;
    }
  }
  ul{
    padding: 1rem;
  }

  h5, a, p{
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: ${props => props.theme.colors.black};
  }

  .logo{
    height: 12vh;
    width: auto;
    margin: 0 auto;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  @media ${device.laptop} {
    font-size: 1.75rem;
    margin: 0 1.25rem;
    position: relative;
  }
  font-size: 1.25rem;
  margin: 0 0.75rem;
  position: relative;
`

const Footer = props => {
  const { socialLinks } = props;
  console.log('footer check', socialLinks)
  return(
    <StyledFooter>
    <Wrapper>
      <Link to="/" aria-label="Home">
          <img alt="logo" src={logo} className="logo"/>
      </Link>
    </Wrapper>
    <SubFooter>
    {
      socialLinks.map((link, index) => (
        <a key={index} href={link.node.url} target="_blank" rel="noopener noreferrer">
        {
          link.node.type == 'Spotify' ?
          <StyledIcon icon={faSpotify} />
          :
          link.node.type == 'Bandcamp' ?
          <StyledIcon icon={faBandcamp} />
          :
          link.node.type == 'Soundcloud' ?
          <StyledIcon icon={faSoundcloud} />
          :
          link.node.type == 'Facebook' ?
          <StyledIcon icon={faFacebook} />
          :
          link.node.type == 'Twitter' ?
          <StyledIcon icon={faTwitter} />
          :
          link.node.type == 'Twitch' ?
          <StyledIcon icon={faTwitch} />
          :
          link.node.type == 'Instagram' ?
          <StyledIcon icon={faInstagram} />
          :
          link.node.type == 'Youtube' ?
          <StyledIcon icon={faYoutube} />
          :
          null
        }
        </a>
      ))
    }
    </SubFooter>
    </StyledFooter>
  )

}

export default Footer
