import React from 'react';
import styled from 'styled-components'
import { device } from '../styles/breakpoints';

const Container = styled.div`
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(${props => props.theme.colors.black}, ${props => props.theme.colors.secondary});
    &::before {
      mix-blend-mode: lighten;
      background-color: ${props => props.theme.colors.black};
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }
`

const BackgroundVideo = styled.video`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 80vh;
    z-index: 100;
    filter: grayscale(100%) contrast(1) blur(0);
    mix-blend-mode: multiply;
    opacity: 1;
`

const Alt = styled.div`
video {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 80vh;
  z-index: 100;
  filter: grayscale(100%) contrast(1) blur(0);
  mix-blend-mode: multiply;
  opacity: 1;
}

video::-webkit-media-controls-overlay-play-button {
  display: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
`


const Hero = props => {
  const { title, subtitle, videoSrc } = props;
  return(
    <Container>
      <Alt
        dangerouslySetInnerHTML={{
          __html:`
          <video
          autoplay
          playsinline
          loop
          muted
          poster=${props.fallbackImg}
          id='video-background'>
            <source src=${videoSrc.file.url} type="video/webm" />
          </video>
          `
        }}
      />
    </Container>
  )

}

export default Hero;
