import React, { Component } from "react"
import styled from 'styled-components'
import { device } from '../styles/breakpoints';
import AudioPlayer from 'react-h5-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp, faChevronCircleDown, faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons'
import { faSpotify, faSoundcloud, faYoutube, faBandcamp } from '@fortawesome/free-brands-svg-icons';
import TagList from '../components/TagList'
import AudioLink from '../components/AudioLink'

const StickyPlayer = styled.div`
@media ${device.laptop} {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: fixed;
  bottom: 10vh;
  width: 10vw;
  height: 10vw;
  margin-left: 89vw;
  transition: all 0.3s;
    &.expanded{
      transition: all 0.3s;
      width: 20vw;
      margin-left: 79vw;
      bottom: 20vh;
      height: auto;
    }
  }
  background-image: linear-gradient(${props => props.theme.colors.white}, ${props => props.theme.colors.secondary});
  box-shadow: 0 0 3px ${props => props.theme.colors.black};
  display: flex;
  flex-flow: row wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  border-radius: 2px;
  *:focus {
    outline: none;
  }
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none;
  }
`

const TrackList = styled.div`
  width: 100%;
  display: block;
  height: 20vh;
  min-height: 50vh;
  overflow: scroll;
  color: ${props => props.theme.colors.black};
  padding: 1em;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none;
  }
`

const Track = styled.div`
  cursor: pointer;
  &:last-child{
    padding-bottom: 2.5rem;
  }
`
const TrackDetails = styled.div`
border-bottom: 1px solid ${props => props.theme.colors.black};

`
const TrackName = styled.h5`
margin: 1em 0;
`
const TrackDescription = styled.p`
margin: 1em 0;
line-height: 1.5rem;
`
const TagsAndLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: 1em 0;
`
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 1.5rem;
  position: absolute;
  bottom: 0.75rem;
  right: 1rem;
  cursor: pointer;
`
const PlayingTrackTitle = styled.div`
  @media ${device.laptop} {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      position: relative;
      padding: 0 1rem 1rem 1rem;
        &.expanded{
          padding: 1.6125rem 1rem 1rem 1rem;
        }
    }
    padding: 1rem;
    p {
      color: ${props => props.theme.colors.black};
    }
`

const StyledPlayback = styled(FontAwesomeIcon)`
  @media ${device.laptop} {
    color: ${props => props.theme.colors.black};
    font-size: 2rem;
    position: relative;
    cursor: pointer;
    margin: 1rem 0 0 1rem;
  }
  color: ${props => props.theme.colors.black};
  font-size: 2rem;
  position: relative;
  cursor: pointer;
  margin: 0.5rem;
`

class AudioWidget extends Component {
  helper(trackID){
    let newIndex;
    for(var i = 0; i < this.props.tracks.length; i++){
      if(trackID == this.props.tracks[i].node.id){
        newIndex = i;
      }
    }
    return newIndex;
  }
  render(){
    const { tracks } = this.props;
    const playIndex = this.props.trackIndex;
    const selectedTrack = tracks[playIndex].node.audio.file.file.url;
    const tags = tracks[playIndex].node.tags;
    const audioLinks = tracks[playIndex].node.audioLinks;
    return(
      <StickyPlayer className={`${this.props.isExpanded ? 'expanded' : ''}`}>
        <AudioPlayer
          src={selectedTrack}
          customProgressBarSection={['']}
          customControlsSection={['MAIN_CONTROLS']}
          showJumpControls={false}
          customIcons={{
            play: <StyledPlayback icon={faPlayCircle} />,
            pause: <StyledPlayback icon={faPauseCircle} />
          }}
        />
        <PlayingTrackTitle className={`${this.props.isExpanded ? 'expanded' : ''}`}>
            <p>Now Playing: {tracks[playIndex].node.title}</p>
        </PlayingTrackTitle>
        <div>
          {
            this.props.isExpanded ?
            <StyledIcon icon={faChevronCircleDown} onClick={() => this.props.collapseAudioPlayer()}/>
            :
            <StyledIcon icon={faChevronCircleUp} onClick={() => this.props.expandAudioPlayer()}/>
          }
        </div>
        {
          this.props.isExpanded ?
          (
            <div>
              <TrackList>
              {
                tracks.map((track, index) =>{
                  return(
                    <Track key={index} onClick={() => this.props.setPlayingtrack(track.node.audio.file.id)}>
                      <TrackName>{track.node.title}</TrackName>
                      {
                        this.props.trackIndex === index ?
                        (
                          <TrackDetails key={index}>
                            <TrackDescription>{track.node.description}</TrackDescription>
                            <TagsAndLinks>
                              {tags && <TagList tags={tags} />}
                            </TagsAndLinks>
                            <TagsAndLinks>
                              {audioLinks && <AudioLink links={audioLinks} />}
                            </TagsAndLinks>
                          </TrackDetails>
                        )
                        :
                        null
                      }
                    </Track>
                  )
                })
              }
              </TrackList>
            </div>
          )
          :
          null
        }
      </StickyPlayer>
    )
  }
}

export default AudioWidget;
