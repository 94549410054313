import React from 'react'
import styled from 'styled-components'

const StyledPriceItem = styled.div`
  margin-bottom: 1em;
  h3, p {
    margin-bottom: 0.5em;
  }
`

const PricingItem = props => {
  const { price, pricingType, projectType, exampleProjects } = props;
  return(
    <StyledPriceItem>
      <h3>{projectType}</h3>
      <p>${price} {pricingType}</p>
      <p>Example Projects:</p>
      {
        exampleProjects.map(e=>{
          return(
            <div>{e.title}</div>
          )
        })
      }
    </StyledPriceItem>
  )
}

export default PricingItem
