const theme = {
  colors: {
    base: '#ff5252', //
    secondary: '#BCE5FB', // Medium Gray
    black: '#203165', // Black
    white: '#ffffff', // White
    baseTint: '#fcfaff',
    highlightTint: '#ffdd59',
    shadow: 'rgba(35, 34, 32, 0.3)',
    shadowDark: 'rgba(35, 34, 32, 0.8)',
    white50: 'rgba(255, 255, 255, 0.5)',
    black50: 'rgba(32, 49, 101, 0.5)',
    black600: '#0F172F'
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  borders: {
    radius: '2px',
    radiusLeft: '',
    radiusRight: '',
  },
  padding: {
    paddingLarge: '3vw',
    paddingMed: '2vw',
    paddingSmall: '1vw',
  }

}

export default theme
