import React, { Component } from 'react'
import Calendly from '../components/Calendly'
import ContactForm from "../components/ContactForm"
import styled from 'styled-components'

const Title = styled.div`
  margin: 2rem auto;
  width: 80%;
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  h5{
    margin-right: 2rem;
    text-decoration: none;
    &.highlighted{
      color: ${props => props.theme.colors.black};
      border-bottom: 5px solid ${props => props.theme.colors.black};
      transition: 0.2s;
    }
  }
`

const ContactSection = props => {
  return(
    <div>
      <Title>
        <h5 onClick={()=> props.showCalendar()} className={`${props.showCal === true ? 'highlighted' : ''}`}>Schedule an Intro Call</h5>
        <h5 onClick={()=> props.hideCalendar()} className={`${props.showCal === true ? '' : 'highlighted'}`}>Send Me a Note</h5>
      </Title>
      {
        props.showCal === true ?
        (
          <Calendly />
        )
        :
        (
          <ContactForm />
        )
      }
    </div>
  )
}

export default ContactSection;
