import React from 'react'
import Helmet from 'react-helmet'
import AudioLink from '../components/AudioLink'
import TagList from '../components/TagList'
import styled from 'styled-components'

const PortfolioBlock = styled.div`
  margin: 0 0 2em 0;
`

const PortfolioTitle = styled.h5`
  color: ${props => props.theme.colors.base};
  margin: 0 0 1em 0;
`

const PortfolioDescription = styled.p`
  color: ${props => props.theme.colors.base};
  margin: 0 0 1em 0;
`

const TagsAndLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: 1em 0;

`

const PortfolioItem = props => {
  const {
    title,
    description,
    audioLinks,
    tags,
    audioUrl,
    audioType
  } = props
  return(
    <PortfolioBlock>
      <PortfolioTitle>{title}</PortfolioTitle>
      <PortfolioDescription>{description}</PortfolioDescription>
      <TagsAndLinks>
        {tags && <TagList tags={tags} />}
        {audioLinks && <AudioLink links={audioLinks} />}
      </TagsAndLinks>
    </PortfolioBlock>
  )
}

export default PortfolioItem
