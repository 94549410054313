import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { device } from '../styles/breakpoints';
require('prismjs/themes/prism.css');

const Body = styled.div`
  margin: 0 auto 2rem 0;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  h1 {
    font-size: 3rem;
    font-weight: 600;
    color: ${props => props.theme.colors.black};
    font-family: 'Aladin', serif;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 400;
    color: ${props => props.theme.colors.black};
    font-family: 'Lora', serif;
    letter-spacing: 0.8px;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${props => props.theme.colors.black};
    font-family: 'Lora', sans-serif;
    letter-spacing: 0.6px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-family: 'Lora', serif;
    color: ${props => props.theme.colors.black};
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.black};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 1em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-family: 'Lora', serif;
      color: ${props => props.theme.colors.black};
      margin: 0.5rem 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
`

const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
`
const Service = styled.div`
  @media ${device.laptop} {
    border: 1px solid ${props => props.theme.colors.black};
    padding: 2rem;
    width: 48%;
    margin: 0 2% 2rem 0;
  }
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 2px;
  padding: 2rem;
  width: 100%;
  margin: 0 auto 2rem 0;
  h3, h4 {
    color: ${props => props.theme.colors.black};
    margin-bottom: 2rem;
    border-bottom: 1px solid ${props => props.theme.colors.black};
    line-height: 3rem;
  }
`

const Featuredtrack = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  cursor: pointer;
  h5 {
    color: ${props => props.theme.colors.black};
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.colors.black};
  font-size: 1.5rem;
  position: relative;
`

const Title = styled.div`
  margin: 2rem auto;
  width: 80%;
  color: ${props => props.theme.colors.black};
  display: block;
  backround-color: ${props => props.theme.colors.black};
`

const Services = props => {
  const { content } = props;
  return(
  <div>
    <Title><h1>Services</h1></Title>
    <Wrapper>
      {
        content.map((service, index) =>{
          return(
            <Service key={index}>
              <h3>{service.node.title}</h3>
              <Body dangerouslySetInnerHTML={{ __html: service.node.description.childMarkdownRemark.html }} />
              {
                service.node.pastWork ?
                <h4>Featured Work</h4>
                :
                null
              }
              {
                service.node.pastWork ?
                (
                  service.node.pastWork.map((item, index) => {
                    return(
                      <Featuredtrack key={index} onClick={() => props.setPlayingtrack(item.audio.file.id)}>
                        <h5>{item.title}</h5>
                        <StyledIcon icon={faPlay} />
                      </Featuredtrack>
                    )
                  })
                )
                :
                null
              }
            </Service>
          )
        })
      }
    </Wrapper>
  </div>
  )
}

export default Services;
