import React from 'react'
import { Link } from 'gatsby'
import { device } from '../styles/breakpoints';
import styled from 'styled-components'
import logo from '../../static/logos/logo_white.svg'


const StyledHeader = styled.header`
  background-color: transparent;
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  z-index: 300;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const Home = styled.div`
  @media ${device.laptop} {
    padding: 0.5rem 2rem;
    .logo {
      height: 12vh;
      width: auto;
      text-decoration: none;
      vertical-align: center;
      font-family: 'Aladin', cursive;
    }
  }
  width: 80%;
  min-width: 280px;
  margin: 0 auto;
  padding: 0.5rem;
  grid-columns: 1 / span 1;
  display: inline;
  text-decoration: none;

  .logo {
    height: 12vh;
    width: auto;
    max-width: 320px;
    text-decoration: none;
    vertical-align: center;
    padding: 0.5rem;
    font-family: 'Aladin', cursive;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.black};
    padding: 0.5rem 0rem;
    font-family: 'Aladin', cursive;
  }
  `
const Contact = styled.div`
width: 20%;
text-align: right;
padding: 2rem 0.5rem;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    font-family: 'Aladin', cursive;
    font-size: 1.4rem;
    font-weight: 400;
  }
`
const Header = props => {
  return(
    <StyledHeader>
      <Home>
        <Link to="/" aria-label="Home">
            <img alt="logo" src={logo} className="logo"/>
        </Link>

      </Home>
      <Contact>
        <Link to="#contact">Contact Me</Link>
      </Contact>
    </StyledHeader>
  )
}

export default Header
