import React from "react"
import { graphql } from 'gatsby'
import App from '../components/App'

const IndexPage = ({ data }) => {
  const { title, subTitle, media } = data.allContentfulHomepageHero.edges[0].node;
  const { body } = data.allContentfulBio.edges[0].node.body;
  const fallbackImg = data.allContentfulFallbackImage.edges[0].node.source.file.url;
  return(
    <App
      title={title}
      subtitle={subTitle}
      videoSrc={media}
      fallbackImg={fallbackImg}
      body={body}
      services={data.allContentfulService.edges}
      slides={data.allContentfulTestimonial.edges}
      gallery={data.allContentfulGalleryPhoto.edges}
      tracks={data.allContentfulPortfolioItem.edges}
      socialLinks={data.allContentfulSocialLink.edges}
    />
  )
}

export default IndexPage

export const query = graphql`
query MyQuery {
  allContentfulPortfolioItem(sort: {fields: order}) {
    edges {
      node {
        id
        description
        title
        audio {
          type
          file {
            file {
              url
            }
            id
          }
        }
        audioLinks {
          type
          url
        }
        tags {
          slug
          title
        }
      }
    }
  }
  allContentfulHomepageHero {
    edges {
      node {
        title
        subTitle
        media {
          file {
            url
          }
        }
      }
    }
  }
  allContentfulFallbackImage {
    edges {
      node {
        source {
          file {
            url
          }
        }
      }
    }
  }
  allContentfulBio {
    edges {
      node {
        body {
          body
        }
      }
    }
  }
  allContentfulPricingItem {
    edges {
      node {
        price
        pricingType
        id
        projectType
        exampleProjects {
          id
          title
        }
      }
    }
  }
  allContentfulGalleryPhoto(sort: {fields: order}) {
    edges {
      node {
        altText
        title
        albumLink
        id
        image {
          fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
        }
      }
    }
  }
  allContentfulTestimonial {
    edges {
      node {
        author
        testimony {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        id
      }
    }
  }
  allContentfulSocialLink {
    edges {
      node {
        url
        type
      }
    }
  }
  allContentfulService(sort: {fields: order}) {
    edges {
      node {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        pastWork {
          title
          audio {
            file {
              file {
                url
              }
              id
            }
            id
          }
        }
      }
    }
  }
}
`
